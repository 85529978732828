div#dashboard {
  .panel {
    border: 3px solid #F3F2F1;
    padding: 1.5rem;
  }

  .double-panel {
    border: 3px solid #F3F2F1;
    margin: 0px;

    .left-side {
      background-color: #F3F2F1;
      padding: 1.5rem;
    }

    .right-side {
      padding: 1.5rem;
    }
  }

  .school-group-cta {
    color: #fff;
    background-color: #f3f2f1;
    padding: 1.5rem;
  }

  #change-school {
    margin: 1em 0;
  }
}

.govuk-hint.govuk-hint__indented {
  margin-left: 3.3rem;
  display: inline-block;
}
