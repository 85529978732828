.pagination-info {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  @include govuk-responsive-margin(6, "bottom");

  @include govuk-media-query($until: tablet) {
    flex-direction: column;
    nav.pagination {
      margin-top: 0.5rem;
      text-align: left;
    }
  }

  div.pagination-slice {
    flex: 1;
  }

  nav.pagination {
    flex: 1;
    flex-grow: 2;
    text-align: right;

    span {
      display: inline-block;
      text-align: center;

      a {
        display: inline-block;
        padding: 0rem 0.2rem;
        min-width: 1rem;
      }

      &.prev {
        margin-right: 0.1rem;
      }

      &.page {
        margin: 0rem 0.2rem;
      }

      &.next {
        margin-left: 0.1rem;
      }
    }
  }
}
