ol.on-boarding-tasks {
  margin-top: 2em;
  list-style: none;
  padding: 0;

  li {
    &::marker {
      @extend .govuk-heading-m;
    }
  }

  .govuk-summary-list {
    .govuk-summary-list__key {
      font-weight: normal;

      span.optional {
        padding-left: 0.2em;
      }
    }

    @include govuk-media-query($from: tablet) {
      .govuk-summary-list__row:first-child {
        .govuk-summary-list__key,
        .govuk-summary-list__actions {
          border-top: 1px solid rgb(177, 180, 182);
        }
      }
    }
  }
}
