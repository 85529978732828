.map-marker {
  height: 0;
  position: absolute;
  width: 200px;

  &:after {
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 8px solid govuk-colour("white");
    bottom: 0;
    content: "";
    height: 0;
    left: 0;
    position: absolute;
    top: 0;
    transform: translate(-50%, -1px);
    width: 0;
    z-index: 1;
  }

  &__close {
    background: none;
    border: none;
    color: govuk-colour("black");
    cursor: pointer;
    display: none;
    font-size: 25px;
    height: 30px;
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    z-index: 1;
  }

  &__title {
    @include govuk-font($size: 14, $line-height: 1.2, $weight: bold);
    cursor: pointer;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__content {
    background-color: govuk-colour("white");
    border: 1px solid $govuk-border-colour;
    box-shadow: 0 1px 2px 1px rgba(govuk-colour("black"), 0.2);
    left: 0;
    max-height: 60px;
    max-width: 120px;
    padding: govuk-spacing(1);
    position: absolute;
    top: 0;
    transform: translate(-50%, -100%);
    overflow-x: hidden;
    z-index: 1;
  }

  &__body {
    display: none;
    position: relative;

    @include mq($from: desktop) {
      min-width: 300px;
    }
  }

  &.open {
    z-index: 2;

    .map-marker__content {
      max-width: none;
      max-height: none;
      padding: govuk-spacing(2);
    }

    .map-marker__title {
      @include govuk-font($size: 19, $line-height: 1.5, $weight: bold);
      @include govuk-responsive-margin(2, "bottom");
      padding-right: 20px;
    }

    .map-marker__body {
      display: block;
    }

    .map-marker__close {
      display: inline-block;
    }
  }

  .govuk-heading-s {
    @include govuk-responsive-margin(2, "bottom");
    padding-right: 20px;
  }

  .govuk-body,
  .govuk-list {
    @include govuk-font($size: 16, $line-height: 1.5);
    @include govuk-responsive-margin(2, "bottom");
  }

  .govuk-list {
    margin-bottom: -10px;
  }
}
