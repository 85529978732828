.preference-info {
  margin-left: 3rem;
}

table#placement-dates {
  td {
    &.status {
      strong {
        &.govuk-tag--available {
          background-color: govuk-colour('green');
        }

        &.govuk-tag--taken {
          background-color: govuk-colour('red');
        }
      }
    }

    &.action-links {
      a {
        display: block;

        &:not(:last-child) {
          margin-bottom: 15px;
        }
      }
    }
  }
}

#bookings_placement_date_date_container {
  fieldset {
    legend {
      margin-bottom: 20px;

      span.govuk-hint {
        margin-bottom: 0;
      }
    }
  }
}
