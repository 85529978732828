.inline-fields {
  display: block;

  legend.govuk-fieldset__legend {
    span.govuk-label {
      margin-bottom: 0;
    }
  }

  .govuk-form-group {
    display: inline-block;
    margin-right: 0.4em;
    margin-bottom: 0;

    label {
      @extend .govuk-label;
      display: inline-block;
      @include govuk-responsive-margin(3, "right");
      margin-bottom: 0;
    }

    input, select {
      width: auto;
    }
  }

  input[type=submit].govuk-button {
    margin-bottom: 0;
    width: auto;
  }

  .govuk-radios {
    display: flex;
    align-items: center;
    legend {
      margin-right: 1rem;
      margin-bottom: 0;
      font-weight: bold;
      span {
        @extend .govuk-label;
      }
    }
  }
}

.govuk-fieldset.inline {
  .govuk-radios__item {
    display: inline-block;
  }
}

.govuk-currency-input {
  display: flex;
  .govuk-currency-input__symbol {
    box-sizing: border-box;
    padding: 8px 10px;
    border: 2px solid #0b0c0c;
    border-right: none;
    background-color: #dee0e2;
    font-weight: bold;
  }
  input {
    text-align: right;
  }
}
