.embedded-map {
  box-sizing: border-box;
  position: relative;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  @include govuk-responsive-margin(2, "bottom");
  height: 0;
  padding-bottom: 67%;

  .embedded-map__inner-container {
    box-sizing: border-box;
    position: absolute;
    margin: 0;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border: 1px solid $govuk-border-colour;
    overflow: hidden;

    img.embedded-map__nojs-img {
      display: block;
      margin: auto auto;
      width: 100%;
      height: auto;
    }
  }

  @include govuk-media-query($from: tablet) {
    width: 100%;
  }
}

/*
body.js-enabled .embedded-map img.embedded-map__nojs-img {
  display: none;
}
*/
