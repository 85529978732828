.school-start-request-button__mobile {
  // hide the button at every size bigger than mobile
  @include govuk-media-query($from: tablet) {
    display: none;
  }
}

.school-start-request-button__tablet_plus {
  // hide the button at sizes smaller than mobile
  @include govuk-media-query($from: mobile, $until: tablet) {
    display: none;
  }
}

.primary-placement-dates {
  li span {
    margin-right: 0.6rem;
  }
}

.school-profile {
  .govuk-summary-list__key {
    margin-bottom: 1em;
  }

  .panel-highlight {
    background: #f3f2f1;
    padding: 20px;

    .govuk-summary-list__key {
      margin-bottom: 0;
    }
  }

  #school-subjects {
    ul {
      margin-top: 1em;
    }
  }

  table thead >tr > th {
    &.placement-date {
      width: 25%;
    }

    &.placement-phase {
      width: 30%;
    }

    &.placement-type {
      width: 27%;
    }

    &.placement-start {
      width: 18%;
    }
  }

  table tbody tr:last-child {
    td, th {
      border-bottom: none;
    }
  }

  .embedded-map {
    width: 100%;
  }
}
