.facet-tags {
  &__group {
    padding: 5px;

    &:nth-child(odd) {
      background-color: #f3f2f1;
    }
  }

  &__wrapper {
    vertical-align: middle;
    margin: 5px 0;
    display: inline-block;

    .facet-tag {
      display: table-cell;
      position: relative;
      padding: 5px;
      border: 1px solid #505a5f;
      border-radius: 5px;
      background-color: #f3f2f1;
      padding: 8px 7px;

      &.interactive {
        padding-left: 23px;

        .facet-tag__remove {
          display: inline-block;
        }
      }

      &__text {
        margin-left: 5px;
        display: inline-block;
      }

      &__remove {
        display: none;
        border: 1px solid transparent;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding: 8px;
        border-radius: 5px;
        font-family: "GDS Transport",arial,sans-serif;
        font-size: 1rem;
        line-height: 1.25;
        background-color: transparent;
        text-align: left;
        font-weight: bold;

        &:focus {
          box-shadow: inset 0 0 0 2px;
          border: solid 1px #0b0c0c;
          outline: 3px solid #fd0;
        }
      }
    }
  }

  &__preposition {
    display: table-cell;
    padding: 0 5px 0 2px;
    vertical-align: middle;
  }
}
