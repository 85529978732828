body {
  @include govuk-text-colour;
  @include govuk-font($size: 16);
}

.column-top-border {
  @include govuk-media-query($from: tablet) {
    @include govuk-responsive-padding(2, "top") ;
    border-top: govuk-spacing(2) solid govuk-colour("blue");
  }
}

div.govuk-phase-banner {
  .govuk-phase-banner__content {
    display: flex;
    flex-direction: row;
    align-items: center;

    .govuk-phase-banner__content__tag, .govuk-phase-banner__text {
      display: inline-block;
    }

    .govuk-phase-banner__text {
      &.phase {
        flex-grow: 1;
      }
    }
  }
}

dl.govuk-summary-list {

  .govuk-summary-list__row > dt.govuk-summary-list__key, dd.govuk-summary-list__value {
    word-break: keep-all;
  }

  // vertical definition lists, title above definition
  &.vertical {
    .govuk-summary-list__row {
      display: block;

      dt.govuk-summary-list__key, dd.govuk-summary-list__value {
        width: inherit;
        display: block;
      }

      dt.govuk-summary-list__key {
        padding-bottom: 0px;
      }

      dd.govuk-summary-list__value {
        padding-top: 0px;
      }
    }
  }

  // inline definition lists, title before defintion inline
  &.inline {
    .govuk-summary-list__row {
      display: block;
      margin: 0.5rem 0rem;

      dt.govuk-summary-list__key, dd.govuk-summary-list__value {
        display: inline;
      }

      dt.govuk-summary-list__key {
        padding-right: 0.2rem;
      }
    }
  }
}

.govuk-button.govuk-button--secondary {
  $govuk-button-secondary-colour: govuk-colour("light-grey");
  $govuk-button-secondary-hover-colour: darken($govuk-button-secondary-colour, 5%);
  $govuk-button-secondary-shadow-colour: darken($govuk-button-secondary-colour, 15%);
  $button-shadow-size: $govuk-border-width-form-element;
  color: $govuk-text-colour;

  background-color: $govuk-button-secondary-colour;
  box-shadow: 0 $button-shadow-size 0 $govuk-button-secondary-shadow-colour; // s0

  &:hover,
  &:focus {
    background-color: $govuk-button-secondary-hover-colour;
  }
}

section.govuk-se-warning {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: lighten(govuk-colour("yellow"), 25%);

  p {
    margin-bottom: 0.1rem;
  }
}

section.govuk-se-letter {
  padding: 1rem;
  $govuk-se-light-blue: #f2f8fc;
  background-color: $govuk-se-light-blue;
  margin-bottom: 1rem;

  .editable {
    background-color: white;
    padding: 2rem;
    border: 2px solid black;
  }
}

ul.govuk-se-spaced-list li {
  @include govuk-responsive-margin(2, "bottom");
}
