[data-controller="collapsible"] {
  /*padding: govuk-spacing(1);*/
  padding: 0;

  & > button[data-action], & > link[data-action] {
    @extend %govuk-heading-s ;
    position: relative;
    width: 100%;
    display: none;
    border: none;
    background: none;
    color: govuk-colour("white") ;
    padding: govuk-spacing(1) ;
    padding-left: govuk-spacing(3) ;
    text-align: left;
    margin: 0;
  }

  @include govuk-media-query($until: tablet) {
    background: govuk-colour("blue") ;
    color: govuk-colour("white");

    & > button[data-action], & > link[data-action] {
      display: block;
    }

    [data-target="collapsible.panel"] {
      padding: govuk-spacing(0) govuk-spacing(1) govuk-spacing(1) ;
    }

    .collapsible__content {
      background: govuk-colour('white') ;
      padding: govuk-spacing(2) ;
    }
  }
}

body.js-enabled [data-controller="collapsible"] {
  &.show [data-target="collapsible.panel"] {
    display: block;
  }

  @include govuk-media-query($until: tablet) {
    [data-target="collapsible.panel"] {
      display: none;
    }

    & > button[data-action], & > link[data-action] {
      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 30px;
        width: 30px;
        background-repeat: no-repeat;
        background-image: url('../images/collapsible-toggle-sprite.png');
        background-position: 0 -80px;
      }
    }

    &.show {
      & > button[data-action], & > link[data-action] {
        &::after {
          background-position: 0 2px;
        }
      }
    }
  }
}
