#school-bookings {
  .school-booking {
    padding: 2rem 0rem;
    margin: 2rem 0rem 1rem;

    &:first-child {
      padding: 1rem 0rem 2rem;
      margin: 1rem 0rem 1rem;
    }

    &:not(:last-child) {
      border-bottom: 10px solid govuk-colour('blue');
    }

  }
}

.school-booking {
  section {
    &:not(:first-child) {
      margin: 3rem 0rem 2rem;
    }
  }

  .status .govuk-tag {
    background-color: govuk-colour('dark-grey');
  }

  .request-options {
    h3 {
      margin-bottom: 1rem;
    }

    .accept-or-reject {
      padding-left: 0rem;
    }
  }
}

#school-booking-show {
  section {
    @include govuk-responsive-margin(9, "bottom");
  }
}

table#bookings {
  tr {
    td.name, td.date {
      font-weight: bold;
    }
  }
}

#bookings-index {
  .pagination-info.higher {
    @include govuk-responsive-margin(4, "bottom");
  }

  .pagination-info.lower {
    @include govuk-responsive-margin(4, "top");
    @include govuk-responsive-margin(4, "bottom");
  }
}

.govuk-tag {
  &.govuk-tag--default {
    background-color: govuk-colour('dark-grey');
  }

  &.govuk-tag--available {
    background-color: govuk-colour('green');
  }
}

