#search-results {
  @include govuk-text-colour;
  @include govuk-font($size: 16);

  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
  }

  ul li {
    margin-left: 0;
    padding-left: 0;
  }

  li.school-result {
    margin-bottom: 1em;
    @extend %govuk-body-m;

    li {
      margin-top: 0.1em;
    }

    a {
      @include govuk-typography-weight-bold($important: true);
      margin-bottom: 1em;
    }
  }

  hr {
    @extend .govuk-section-break ;
    @extend .govuk-section-break--m ;
    @extend .govuk-section-break--visible ;
  }

  .loading {
    margin-bottom: 1em;
    display: none;

    &.active {
      display: block;
    }
  }

  .school-result {
    padding: 0 govuk-spacing(4) 0 govuk-spacing(6);
    margin: govuk-spacing(4) 0;
    border-left: 5px solid govuk-colour('turquoise');

    dl.govuk-summary-list {
      margin-bottom: 1rem;

      dt.govuk-summary-list__key, dd.govuk-summary-list__value {
        word-break: normal;
      }

      .govuk-summary-list__row:nth-last-child(1) {
        dt.govuk-summary-list__key,
        dd.govuk-summary-list__value {
          border-bottom: none;
        }
      }
    }
  }

  .expanded-search-radius {
    padding: govuk-spacing(2) govuk-spacing(4) govuk-spacing(2) govuk-spacing(6);
    margin: govuk-spacing(4) 0 govuk-spacing(6);
    border-left: 5px solid govuk-colour('red');

    h3 {
      margin-bottom: govuk-spacing(1) ;
    }

    .nearby-info {
      margin-bottom: 0rem;
      p:last-child {
        margin-bottom: 0rem;
      }
    }
  }
}

.filter-list fieldset legend span.govuk-label {
  @extend %govuk-heading-m ;
}

.filter-list {
  margin-bottom: govuk-spacing(4);
  background-color: govuk-colour("light-grey");

  .filter {
    border-bottom: 1px solid govuk-colour("mid-grey");
    padding: 10px;
    margin: 10px 0px;

    &:last-of-type {
      border-bottom: none;
    }
  }

  button[type=submit] {
    margin-left: 10px;
  }

}


#search-filter {
  button[type='submit'].hidden {
    display: none;
  }
}

.search-subjects-checkboxes {
  height: 300px;
  overflow-y: scroll;
}

.school-search-form {
  select {
    width: 100%;
  }
}

#candidate-school-profile {
  ul#school-websites {
    @extend .govuk-list ;
    @extend .govuk-list--bullet ;
    @extend .govuk-body-s ;

    a {
      @extend .govuk-link ;
    }

    li {
      @include govuk-responsive-margin(4, "top") ;
      @include govuk-responsive-margin(4, "bottom") ;
    }
  }

  .directions-link {
    margin-top: 0;
    @include govuk-font($size: 16) ;
  }
}

#skip-to-results{
  &:focus {
    margin-bottom: 10px !important;
  }
}

#candidate-application-notification {
  margin-top: 0;
}

#search-bar {
  hr {
    @extend .govuk-section-break ;
    @extend .govuk-section-break--m ;
    @extend .govuk-section-break--visible ;
  }

  form {
    display: flex;
    flex-direction: column;

    input,
    select {
      min-width: 12em;
      width: 100%;
    }

    @include govuk-media-query($from: tablet) {
      flex-direction: row;
      .govuk-form-group {
        margin-right: 2em;
        margin-bottom: 0em !important;
      }

      .govuk-button {
        margin-top: 1.5em;
      }
    }
  }
}
