#application-preview {
  dl.govuk-summary-list {
    h2.govuk-heading-m {
      &:nth-of-type(2) {
        margin-top: 60px;
      }
    }
    .govuk-summary-list__key {
      word-break: break-word;
    }
  }
}
