.govuk-cookie-banner {
  form {
    display: contents;
  }
}

#cookie-banner {
  background-color: lighten(govuk-colour("blue"), 55%);
  padding: 0.6rem 0rem 0.4rem;

  #cookie-banner-message {
    > p {
      color: $govuk-text-colour;

      // forego the underline, it interferes with the header bar immediately
      // below
      > a {
        text-decoration: none;

        // darken when hovering because the light blue background doesn't
        // provide enough contrast
        &:hover {
          color: darken($govuk-link-colour, 15%);
        }
      }
    }

    @include govuk-media-query($from: tablet) {
      form {
        display: inline-block;
        margin-right: 1em;
      }

      div.govuk-grid-column-one-third {
        text-align: right;
      }
    }

    @include govuk-media-query($until: tablet) {
      div.govuk-grid-column-one-third {
        margin-top: govuk-spacing(2);
      }
    }
  }
}

#cookies-tables {
  table td.govuk-table__cell:first-of-type {
    white-space: nowrap;
  }
}
